.ui-gateway-identity-container {
  padding: 24px 24px 12px 24px;
  background-color: #303030;
}

.page-title {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  text-align: center;
  color: #dcdcdc;
}

.gw-form-field .ui.scuf-dropdown-wrapper {
  width: 368px;
}

.gw-form-field {
  margin-top: 20px;
}

.content-loading {
  position: relative;
  top: 48%;
  margin-left: 48%;
}

.identity-wrapper {
  margin-top: 60px;
}

.list-wrapper {
  margin-top: 27px;
}

.gw-form-field .dropdown {
  background: #202020 !important;
}
