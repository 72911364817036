@import './../common';

.contact-support-container {
  @include ui-page-container
}

.cntct-sprt-tel-phnum {
  
}

.ph-num-holder {
  
}

.ph-num-holder div {
  
}

.ph-num-holder a {
  font-size: 14px;
}

.option-msg div {
  padding-left:54px;
  font-size:16px;
}

.ui-contact-supp-navigation {
  @include ui-navigation-content;
}

.ui-contact-supp-navigation button {
  width: 100%;
}

.ui-conct-sprt-msg {
  font-size: 16px;
  line-height:26px;
}

@media screen and (min-width: $mobileBreakPoint) {
  .contact-support {
    overflow-y: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .ui-contact-supp-navigation button {
    width: auto;
    min-width:200px !important;
  }
  .ui-conct-sprt-msg {
    text-align: left;
    margin-left: 243px;
  }

  .ui-diy-status-icon{
    margin-top:43px;
  }

}
