@import "./../../common";

.ui-gateway-block-selected {
    @include ui-info-card;
    background: $siteBlockBackgroundColorSelected;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    height: 60px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
.ui-gateway-block-unselected {
    @include ui-info-card;
    background: $siteBlockBackgroundColor;
    height: 60px;
    margin-top: 0px;
    margin-bottom: 12px;
  }